<template>
    <div>
        <div class="">
            <div class="details-holder">
                <div>
                    <img src="../assets/images/user.svg" alt="Profile-image">
                </div>
                <div class="contact-info">
                    <h5>Charles Ubokulom Amos</h5>
                    <h6>Abuja, Nigeria</h6>
                    <h5>0</h5>
                    <button class="btn">Contant Agent</button>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
// import { Service } from "../store/service";
// import $ from "jquery";
// const Api = new Service();

export default {
  name: "AgentSheltaContact",
  components: { 
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
   
  },
  methods: {
    any() {
      this.$router.push("/property-details/12");
    }
    
  }
};
</script>


<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$sheltaBlack: #2B3352;


.details-holder {
    display: flex;
}

</style>